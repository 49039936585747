import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "nod-clients"
    }}>{`NOD clients`}</h1>
    <p>{`Pickup of an OrderGroup is always performed in the same way regardless of what order is picked up, or which scenario Pickup follows. A NOD Client declares what properties it possesses when a Pickup is initiated in the form of Capabilities and then follows the slavish client commands it receives until no more. NOD adjusts the Client commands returned to which Capabilities the client possesses. This means that future client-side functionality can be rolled out without existing NOD Clients having to be upgraded. See `}{`[HB206.22]`}{` for which Capabilities NOD Platform has or is planning support.`}</p>
    <h3 {...{
      "id": "following-are-the-most-important-capabilities-a-nod-client-can-support-today"
    }}>{`Following are the most important Capabilities a NOD Client can support today:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`9: Client supports Media Independent Mobile 2D Barcode.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`16: Client supports DESFire APDU commands.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`27: Client supports display of Messages`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`7: Client supports UltraLight APDU commands.`}</p>
      </li>
    </ul>
    <p>{`When placing an Order in an Order Group, a requirement for a minimum of Capabilities is built up for the order to be picked up by Clients. This mechanism means that an Order Group can never be picked up by Clients who cannot execute all Orders in the Order Group.
To develop a NOD Client for displaying mobile tickets, the NOD Client must at a minimum support Capability 9. That is, the NOD Client must be able to execute the client command qrTicketCommand.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      